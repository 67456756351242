import Api from './Api.js';

const organization_id = () => {
  return window.localStorage.getItem("OrganizationID");
};

export default {
  getDprList(filters, sort) {
    let url = `/v2/dpr/${filters.project_id}?organization_id=${organization_id()}&dateFrom=${filters.fromDate}&dateTo=${filters.toDate}&page_no=${filters.page}`
    if (filters.hasOwnProperty("saveStatus")) {
      url += '&saveStatus=1';
    }
    if (filters.hasOwnProperty("search")) {
      url += `&search=${filters.search}`;
    }
    return Api().get(url);

  },
  getDPRreasonsByBudget(budgetId) {
    let url = `/v2/dpr/getDPRreasonsByBudget/${budgetId}?organization_id=${organization_id()}`

    return Api().get(url);

  },
  addDpr(payload) {
    return Api().post(`/v2/dpr?organization_id=${organization_id()}`, payload);
  },
  updateAndSaveAll(payload, project_id) {
    return Api().put(`/v2/dpr/${project_id}?organization_id=${organization_id()}`, payload);
  },
  getParticularList(paylaod) {
    let url = `/v2/dpr/getDPRparticulars/${paylaod.project_id}/${paylaod.dpr_id}?organization_id=${organization_id()}`

    return Api().get(url);
  },

  // **************************

  AddDPR(payload){
    return Api().post(`/v2/dcr?organization_id=${organization_id()}`, payload);
  },

  getDailyCostReport(project_id, dcr_date, category_id){
    return Api().get(`/v2/dcr/${project_id}&${dcr_date}&${category_id}?organization_id=${organization_id()}`);
  }

}
