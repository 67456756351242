<template>
  <div>
    <span class="text-center table-edit-icon" @click="getContractReason()">
      <i class="text-center material-icons">remove_red_eye</i>
    </span>
    <div class="demo-alignment">
      <vs-popup
        fullscreen
        :id="'popmodalView' + params.data.fla_id"
        class="holamundo p-2"
        title="Contract Service Reason"
        :active.sync="popupActive"
      >
        <div class="row mx-0 text-center mt-5 mb-base">
          <div class="col-4">
            <h6>Company Name:</h6>
            <h3 class="text-primary">{{ params.data.company_name }}</h3>
          </div>
          <div class="col-4">
            <h6>Project Name:</h6>
            <h3 class="text-primary">{{ params.data.contract_project_name }}</h3>
          </div>
          <div class="col-4">
            <h6>Vendor Name:</h6>
            <h3 class="text-primary">{{ params.data.vendor_name }}</h3>
          </div>
        </div>

        <div>
          <div class="col-12 row">
            <div class="col-10"></div>
            <div class="col-2">
              <vs-input
                class="mb-4 md:mb-0 mr-4 ml-3"
                v-model="searchQuery"
                @input="getContractReason"
                placeholder="Search..."
              />
            </div>
          </div>
          <ag-grid-vue
            :gridOptions="gridOptions"
            class="ag-theme-material w-100 my-4 ag-grid-table"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :rowClicked="onRowDataChanged"
            :rowData="budgetLogs"
            rowSelection="multiple"
            colResizeDefault="shift"
            :animateRows="true"
            :floatingFilter="false"
          ></ag-grid-vue>
          <!-- :paginationPageSize="paginationPageSize"
          :pagination="true"
          :suppressPaginationPanel="true"-->
          <!-- <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" /> -->
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { AgGridVue } from "ag-grid-vue";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DepartmentService from "@/services/departmentService";
import ContractService from "@/services/ContractService.js";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import DPRService from "@/services/dprService";

import moment from "moment";
// var moment = require("moment");
// moment().format();
export default Vue.extend({
  components: {
    AgGridVue
  },
  data() {
    return {
      params: null,

      budgetList: [],
      totalPages: 0,
      popupActive: false,
      gridApi: null,
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      budgetLogs: [],
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: []
      // project_id: ""
    };
  },

  beforeMount: function() {
    this.fla_id = this.params.data.fla_id;
    // this.params.datas = this.params.data;
    this.columnDefs = [
      // {
      //   headerName: "Date",
      //   field: "type_date",
      //   filter: false,
      //   width: 300,
      //   suppressSizeToFit: true
      // },
      {
        headerName: "Reason",
        field: "reason",
        filter: false,
        width: 400
      },
      {
        headerName: "Created By",
        field: "fla_reason_created_by",
        filter: false,
        width: 300
      },

      {
        headerName: "Created Date",
        field: "fla_reason_created_date",
        filter: false,
        width: 300,
        suppressSizeToFit: true
      }

      // cellRendererFramework: viewBudget
    ];
  },
  flanted() {
    // if (this.checkPermission(["department", "GET"])) {
    //   this.getDepartmentList(this.currentPage);
    // }

    this.gridApi = this.gridOptions.api;
  },
  beforeDestroy() {
    let _id = document.getElementById("popmodalView" + this.params.data.fla_id);
    _id.setAttribute("style", "display: none;");
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          //   this.getDepartmentList(val);
          return val;
        } else return 1;
      }
    }
  },
  methods: {
    onRowDataChanged(e) {
      console.log("e :", e);
    },
    onChangeStatus: function() {
      console.log("object");
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    getContractReason: function() {
      let payload = {
        fla_id: this.fla_id
      };
      if (this.searchQuery != "") {
        payload["search"] = this.searchQuery;
      }
      this.popupActive = true;
      //console.log("dsfdsfsdfsd");
      ContractService.getReasonFla(payload)
        .then(response => {
          const { data } = response;
          // console.log(response, "fdsfdsfsdf");
          if (data.Status) {
            this.budgetLogs = data.data;
            // this.getBudgetLogList();
            if (screen.width > 1200) {
              this.gridApi.sizeColumnsToFit();
            }
          } else {
          }
        })
        .catch(error => {
          console.log("error :", error);
        });
    }
  }
});
</script>

<style></style>
