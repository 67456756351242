<!-- =========================================================================================
    File Name: AgGridTable.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div id="ag-grid-demo">
      <vx-card class="form-title" title="Select Form Type">
        <div class="row">
          <div class="col-2 mb-2"></div>
          <div class="col-4 mb-2">
            <h6>Contract Form Type</h6>
            <multiselect
              label="name"
              class="w-full"
              track-by="name"
              v-model="form.typeOfAgreement"
              name="contract Form"
              :options="formType"
              :searchable="true"
              :allow-empty="false"
              open-direction="bottom"
              placeholder="Select Option"
              deselect-label="Can't remove this value"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.name }}
              </template>
            </multiselect>
            <p class="error-msg text-sm">
              <span
                class="text-danger text-sm"
                v-show="errors.has('Vender Name')"
                >{{ errors.first("Vender Name") }}</span
              >
            </p>
          </div>
          <div class="col-4 mb-2">
            <h6>Project Name</h6>
            <multiselect
              label="project_name"
              class="w-full"
              value="project_id"
              v-model="form.project_id"
              name="Project Name"
              :options="projectList"
              :searchable="true"
              :allow-empty="true"
              open-direction="bottom"
              placeholder="Select Option"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.project_name }}
              </template>
            </multiselect>
            <p class="error-msg text-sm">
              <span
                class="text-danger text-sm"
                v-show="errors.has('Vender Name')"
                >{{ errors.first("Vender Name") }}</span
              >
            </p>
          </div>
        </div>
        <div class="text-center">
          <vs-button
            type="filled"
            class="hover-btn mr-3"
            @click.prevent="submitForm(1)"
            >Submit</vs-button
          >
        </div>
      </vx-card>

      <div class="vx-col w-full">
        <vs-tabs v-model="tabIndexCheck">
          <vs-tab
            label="List view"
            icon-pack="feather"
            icon="icon-box"
            @click="checkRouteTab(1)"
          >
            <!-- CONTARCT FORMS LIST -->
            <vx-card
              class="form-title"
              :title="(selectedForm.name || '') + ' List'"
            >
              <!-- <h6>{{ selectedForm.name || ''+ ' List'}}</h6>> -->
              <div v-if="mouFormTable && contractFormList.length > 0">
                <div class="flex flex-wrap justify-end items-center">
                  <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
                  <div
                    class="
                      flex flex-wrap
                      items-center
                      justify-between
                      ag-grid-table-actions-right
                    "
                  >
                    <vs-input
                      class="mb-4 md:mb-0 mr-4"
                      v-model="searchQuery"
                      @input="getAllContractMouFormList('1', '2')"
                      placeholder="Search..."
                    />
                    <vx-tooltip text="Export PDF">
                      <a
                        :href="
                          excelurl +
                          `/v2/formMou/downloadPDF?organization_id=${organization_id}&token=${temp_token}&mou_id=${PDFMOUARRAY.join(
                            ','
                          )}`
                        "
                        target="_blank"
                        v-if="PDFMOUARRAY.length > 0"
                      >
                        <vs-button class="px-4 mr-3">
                          <span class="d-inline-flex pr-5 text-white"
                            >Export PDF</span
                          >
                          <img
                            src="@/assets/images/icons/export-excel.svg"
                            class="d-inline-flex"
                            height="20"
                            width="20"
                          />
                        </vs-button>
                      </a>
                      <vs-button class="px-4 mr-3" v-else disabled>
                        <span class="d-inline-flex pr-5 text-white"
                          >Export PDF</span
                        >
                        <img
                          src="@/assets/images/icons/export-excel.svg"
                          class="d-inline-flex"
                          height="20"
                          width="20"
                        />
                      </vs-button>
                    </vx-tooltip>
                    <vx-tooltip text="Export Excel">
                      <a
                        :href="
                          excelurl +
                          `/v2/formMou/excel/download?organization_id=${organization_id}&token=${temp_token}`
                        "
                        target="_blank"
                      >
                        <vs-button class="px-4">
                          <span class="d-inline-flex pr-5 text-white"
                            >Export</span
                          >
                          <img
                            src="@/assets/images/icons/export-excel.svg"
                            class="d-inline-flex"
                            height="20"
                            width="20"
                          />
                        </vs-button>
                      </a>
                    </vx-tooltip>
                  </div>
                </div>
                <ag-grid-vue
                  :gridOptions="gridOptions"
                  class="ag-theme-material w-100 my-4 ag-grid-table"
                  :columnDefs="columnDefs"
                  :defaultColDef="defaultColDef"
                  :rowClicked="onRowDataChanged"
                  :rowData="contractFormList"
                  rowSelection="multiple"
                  colResizeDefault="shift"
                  :animateRows="true"
                  :floatingFilter="false"
                  :paginationPageSize="paginationPageSize"
                  :pagination="true"
                  :suppressPaginationPanel="true"
                ></ag-grid-vue>
                <vs-pagination
                  :total="totalPages"
                  :max="maxPageNumbers"
                  v-model="currentPage"
                />
              </div>
              <div v-if="flaFormTable && contractFormListFla.length > 0">
                <div class="flex flex-wrap justify-end items-center">
                  <div
                    class="
                      flex flex-wrap
                      items-center
                      justify-between
                      ag-grid-table-actions-right
                    "
                  >
                    <vs-input
                      class="mb-4 md:mb-0 mr-4"
                      v-model="searchQuery"
                      @input="getAllContractFlaFormList('1', '2')"
                      placeholder="Search..."
                    />
                    <vx-tooltip text="Export PDF">
                      <a
                        :href="
                          excelurl +
                          `/v2/formFla/downloadPDF?organization_id=${organization_id}&token=${temp_token}&fla_id=${PDFFLAARRAY.join(
                            ','
                          )}`
                        "
                        target="_blank"
                        v-if="PDFFLAARRAY.length > 0"
                      >
                        <vs-button class="px-4 mr-3">
                          <span class="d-inline-flex pr-5 text-white"
                            >Export PDF</span
                          >
                          <img
                            src="@/assets/images/icons/export-excel.svg"
                            class="d-inline-flex"
                            height="20"
                            width="20"
                          />
                        </vs-button>
                      </a>
                      <vs-button class="px-4 mr-3" v-else disabled>
                        <span class="d-inline-flex pr-5 text-white"
                          >Export PDF</span
                        >
                        <img
                          src="@/assets/images/icons/export-excel.svg"
                          class="d-inline-flex"
                          height="20"
                          width="20"
                        />
                      </vs-button>
                    </vx-tooltip>
                    <vx-tooltip text="Export Excel">
                      <a
                        :href="
                          excelurl +
                          `/v2/formFla/excel/download?organization_id=${organization_id}&token=${temp_token}`
                        "
                        target="_blank"
                      >
                        <vs-button class="px-4">
                          <span class="d-inline-flex pr-5 text-white"
                            >Export</span
                          >
                          <img
                            src="@/assets/images/icons/export-excel.svg"
                            class="d-inline-flex"
                            height="20"
                            width="20"
                          />
                        </vs-button>
                      </a>
                    </vx-tooltip>
                  </div>
                </div>
                <ag-grid-vue
                  :gridOptions="gridOptions"
                  class="ag-theme-material w-100 my-4 ag-grid-table"
                  :columnDefs="columnDefsFla"
                  :defaultColDef="defaultColDef"
                  :rowClicked="onRowDataChanged"
                  :rowData="contractFormListFla"
                  rowSelection="multiple"
                  colResizeDefault="shift"
                  :animateRows="true"
                  :floatingFilter="false"
                  :paginationPageSize="paginationPageSize"
                  :pagination="true"
                  :suppressPaginationPanel="true"
                ></ag-grid-vue>
                <vs-pagination
                  :total="totalPages"
                  :max="maxPageNumbers"
                  v-model="currentPage"
                />
              </div>

              <div v-if="showNewTable && this.searchQuery != ''">
                <div class="flex flex-wrap justify-end items-center">
                  <div
                    class="
                      flex flex-wrap
                      items-center
                      justify-between
                      ag-grid-table-actions-right
                    "
                  >
                    <vs-input
                      class="mb-4 md:mb-0 mr-4"
                      v-model="searchQuery"
                      @input="submitForm(2)"
                      placeholder="Search..."
                    />
                  </div>
                </div>
                <ag-grid-vue
                  :gridOptions="gridOptions"
                  class="ag-theme-material w-100 my-4 ag-grid-table"
                  :columnDefs="columnDefsFla"
                  :defaultColDef="columnDefsDraft"
                  :rowClicked="onRowDataChanged"
                  :rowData="contractFormListFla"
                  rowSelection="multiple"
                  colResizeDefault="shift"
                  :animateRows="true"
                  :floatingFilter="false"
                  :paginationPageSize="paginationPageSize"
                  :pagination="true"
                  :suppressPaginationPanel="true"
                ></ag-grid-vue>
                <vs-pagination
                  :total="totalPages"
                  :max="maxPageNumbers"
                  v-model="currentPage"
                />
              </div>
            </vx-card>
          </vs-tab>

          <vs-tab
            label="Draft View"
            icon-pack="feather"
            icon="icon-box"
            @click="checkRouteTab(2)"
          >
            <!-- DRAFT -->
            <vx-card class="form-title" title="Draft View">
              <!-- <div v-if="draftList.length>0"> -->
              <ag-grid-vue
                :gridOptions="gridOptions"
                class="ag-theme-material w-100 my-4 ag-grid-table"
                :columnDefs="columnDefsDraft"
                :defaultColDef="defaultColDef"
                :rowData="draftList"
                rowSelection="multiple"
                colResizeDefault="shift"
                :animateRows="true"
                :floatingFilter="false"
                :paginationPageSize="paginationPageSize"
                :pagination="true"
                :suppressPaginationPanel="true"
              ></ag-grid-vue>

              <vs-pagination
                :total="totalPages"
                :max="maxPageNumbers"
                v-model="currentPage"
              />
              <!-- </div> -->
            </vx-card>
          </vs-tab>
        </vs-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { BASEURL } from "@/config/index.js";

import editContractForm from "./editContractForm";
import viewContractMouReason from "./viewContractMouReason";
import viewContractFlaReason from "./viewContractFlaReason";
import createDuplicateMou from "./createDuplicateMou";
import pdfSelectionMou from "./pdfSelectionMou";
import pdfSelectionFLA from "./pdfSelectionFLA";
import hiearachyShowMou from "./hiearachyShowMou";
import hiearachyShowFla from "./hiearachyShowFla";
import createDuplicateFla from "./createDuplicateFla";
import editContractFlaForm from "./editContractFlaForm";
import moveToBudget from "./moveToBudget.vue";
import moveToBudgetFLA from "./moveToBudgetFLA.vue";

import ContractService from "@/services/ContractService.js";
import projectService from "@/services/projectService.js";
import contractDocuments from "./contractDocuments.vue";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import viewContractMouReasonVue from "./viewContractMouReason.vue";
window.isFirstColumn = function isFirstColumn(params) {
  var displayedColumns = params.columnApi.getAllDisplayedColumns();
  var thisIsFirstColumn = displayedColumns[0] === params.column;
  return thisIsFirstColumn;
};

export default {
  components: {
    AgGridVue,
  },
  data() {
    return {
      projectList: [],

      newContract: [],
      excelurl: BASEURL(),
      totalPages: 0,
      draftList: [],
      ListVieWName: "",
      searchQuery: "",
      tabIndexCheck: 0,
      mouFormTable: false,
      flaFormTable: false,
      gridOptions: {
        onCellEditingStarted: this.onTableCellEditorNew,
      },
      maxPageNumbers: 7,
      gridApi: null,
      selectedForm: {
        name: "MOU",
      },
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      form: {
        typeOfAgreement: {},
        project_id: "",
      },
      formType: [],
      contractFormList: [],
      columnDefs: [],
      contractFormListFla: [],
      columnDefsDraft: [],
      PDFMOUARRAY: [],
      PDFFLAARRAY: [],
    };
  },
  beforeMount() {
    this.getAgreement();
    this.columnDefs = [
      {
        headerName: "",
        field: "",
        filter: false,
        width: 100,
        suppressSizeToFit: true,
        cellRendererFramework: pdfSelectionMou,
      },
      {
        headerName: "Approval Status",
        field: "",
        filter: false,
        width: 150,
        suppressSizeToFit: true,
        cellRendererFramework: hiearachyShowMou,
      },
      {
        headerName: "Sr. No",
        field: "",
        filter: false,
        width: 100,
        suppressSizeToFit: true,
        cellRenderer: function (params) {
          //  console.log("params PPPP :>> ", params.value);
          return `<span class="text-center d-table">${
            params.rowIndex + 1
          }</span>`;
        },
      },
      {
        headerName: "Duplicate Form",
        field: "",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
        cellRendererFramework: createDuplicateMou,
      },
      {
        headerName: "Created Date",
        field: "mou_created_date",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: "Created By",
        field: "mou_created_by_name",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: "Company name",
        field: "company_name",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
        cellRendererFramework: editContractForm,
      },
      {
        headerName: "Project Name",
        field: "contract_project_name",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
        cellRendererFramework: editContractForm,
      },
      {
        headerName: "Category",
        field: "category_name",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: "Subcategory",
        field: "sub_category_name",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
      },
      // {
      //   headerName: "Contract value",
      //   field: "contractValue",
      //   filter: false,
      //   width: 200,
      //   suppressSizeToFit: true
      // },
      {
        headerName: "Start date",
        field: "dateFrom",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: "End date",
        field: "dateTo",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: "Status",
        field: "status_name",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
        cellRenderer: function (params) {
          if (params.data.status_budgetactive == 1) {
            return `<span class="text-center" style="color:green">${params.data.status_name}</span>`;
          } else {
            return `<span class="text-center" style="color:red">${params.data.status_name}</span>`;
          }
        },
      },
      {
        headerName: "Priority",
        field: "priorityValue",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
        cellRenderer: function (params) {
          if (params.value == 1) {
            return `<span class="text-center dot-edit-icon high"></span>`;
          } else if (params.value == 2) {
            return `<span class="text-center dot-edit-icon normal"></span>`;
          } else if (params.value == 3) {
            return `<span class="text-center dot-edit-icon low"></span>`;
          } else if (params.value == 4) {
            return `<span class="text-center d-table">-</span>`;
          }
        },
      },
      {
        headerName: "Reason",
        field: "",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
        cellRendererFramework: viewContractMouReason,
      },
      {
        headerName: "Attachment",
        field: "uploadDocument",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
        cellRendererFramework: contractDocuments,
      },
      {
        headerName: "Link to Budget",
        field: "",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
        cellRendererFramework: moveToBudget,
      },
    ];
    this.columnDefsFla = [
      {
        headerName: "",
        field: "",
        filter: false,
        width: 100,
        suppressSizeToFit: true,
        cellRendererFramework: pdfSelectionFLA,
      },
      {
        headerName: "Approval Status",
        field: "",
        filter: false,
        width: 150,
        suppressSizeToFit: true,
        cellRendererFramework: hiearachyShowFla,
      },
      {
        headerName: "Sr. No",
        field: "",
        filter: false,
        width: 100,
        suppressSizeToFit: true,

        cellRenderer: function (params) {
          //  console.log("params PPPP :>> ", params.value);
          return `<span class="text-center d-table">${
            params.rowIndex + 1
          }</span>`;
        },
      },
      {
        headerName: "Duplicate Form",
        field: "",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
        cellRendererFramework: createDuplicateFla,
      },
      {
        headerName: "Created Date",
        field: "fla_created_date",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: "Created By",
        field: "fla_created_by_name",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: "Company name",
        field: "company_name",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
        cellRendererFramework: editContractFlaForm,
      },
      {
        headerName: "Project Name",
        field: "contract_project_name",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
        cellRendererFramework: editContractFlaForm,
      },
      {
        headerName: "Category",
        field: "category_name",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: "Subcategory",
        field: "sub_category_name",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: "Contract value",
        field: "contractValue",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: "Start date",
        field: "dateFrom",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: "End date",
        field: "dateTo",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: "Status",
        field: "status_name",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
        cellRenderer: function (params) {
          if (params.data.status_budgetactive == 1) {
            return `<span class="text-center" style="color:green">${params.data.status_name}</span>`;
          } else {
            return `<span class="text-center" style="color:red">${params.data.status_name}</span>`;
          }
        },
      },
      {
        headerName: "Priority",
        field: "priorityValue",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
        cellRenderer: function (params) {
          console.log("params PPPP :>> ", params.value);
          if (params.value == 1) {
            return `<span class="text-center dot-edit-icon high"></span>`;
          } else if (params.value == 2) {
            return `<span class="text-center dot-edit-icon normal"></span>`;
          } else if (params.value == 3) {
            return `<span class="text-center dot-edit-icon low"></span>`;
          } else if (params.value == 4) {
            return `<span class="text-center d-table">-</span>`;
          }
        },
      },
      {
        headerName: "Reason",
        field: "",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
        cellRendererFramework: viewContractFlaReason,
      },
      {
        headerName: "Attachment",
        field: "uploadDocument",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
        cellRendererFramework: contractDocuments,
      },

      {
        headerName: "Link to Budget",
        field: "",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
        cellRendererFramework: moveToBudgetFLA,
      },
    ];

    this.columnDefsDraft = [
      {
        headerName: "Sr. No",
        field: "",
        filter: false,
        width: 100,
        suppressSizeToFit: true,
        cellRenderer: function (params) {
          //  console.log("params PPPP :>> ", params.value);
          return `<span class="text-center d-table">${
            params.rowIndex + 1
          }</span>`;
        },
      },
      // {
      //   headerName: "Duplicate Form",
      //   field: "",
      //   filter: false,
      //   width: 200,
      //   suppressSizeToFit: true,
      //   cellRendererFramework: createDuplicateMou
      // },
      {
        headerName: "Created Date",
        field: "mou_created_date",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: "Created By",
        field: "mou_created_by_name",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: "Company name",
        field: "company_name",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
        cellRendererFramework: editContractForm,
      },
      {
        headerName: "Project Name",
        field: "contract_project_name",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
        cellRendererFramework: editContractForm,
      },
      {
        headerName: "Category",
        field: "category_name",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: "Subcategory",
        field: "sub_category_name",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
      },
      // {
      //   headerName: "Contract value",
      //   field: "contractValue",
      //   filter: false,
      //   width: 200,
      //   suppressSizeToFit: true
      // },
      // {
      //   headerName: "Start date",
      //   field: "dateFrom",
      //   filter: false,
      //   width: 200,
      //   suppressSizeToFit: true
      // },
      // {
      //   headerName: "End date",
      //   field: "dateTo",
      //   filter: false,
      //   width: 200,
      //   suppressSizeToFit: true
      // },
      // {
      //   headerName: "Status",
      //   field: "status_name",
      //   filter: false,
      //   width: 200,
      //   suppressSizeToFit: true
      // },
      // {
      //   headerName: "Priority",
      //   field: "priorityValue",
      //   filter: false,
      //   width: 200,
      //   suppressSizeToFit: true,
      //   cellRenderer: function(params) {
      //     if (params.value == 1) {
      //       return `<span class="text-center dot-edit-icon high"></span>`;
      //     } else if (params.value == 2) {
      //       return `<span class="text-center dot-edit-icon normal"></span>`;
      //     } else if (params.value == 3) {
      //       return `<span class="text-center dot-edit-icon low"></span>`;
      //     } else if (params.value == 4) {
      //       return `<span class="text-center d-table">-</span>`;
      //     }
      //   }
      // },
      // {
      //   headerName: "Reason",
      //   field: "",
      //   filter: false,
      //   width: 200,
      //   suppressSizeToFit: true,
      //   cellRendererFramework: viewContractMouReason
      // },
      {
        headerName: "Attachment",
        field: "uploadDocument",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
        cellRendererFramework: contractDocuments,
      },
    ];

    // this.setColums();
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
  },
  computed: {
    organization_id() {
      return localStorage.getItem("OrganizationID");
    },
    showNewTable() {
      if (
        this.contractFormList.length == 0 &&
        this.contractFormListFla.length > 0
      ) {
        return false;
      } else if (
        this.contractFormList.length > 0 &&
        this.contractFormListFla.length == 0
      ) {
        return false;
      } else {
        return true;
      }
    },
    selectedTab() {
      return this.selectedForm.name;
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    // totalPages() {
    //   if (this.gridApi) return this.gridApi.paginationGetTotalPages();
    //   else return 0;
    // },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          if (this.tabIndexCheck == 0) {
            this.submitForm(2, val);
          }
          return val;
        } else return 1;
      },
    },
  },
  methods: {
    getProjectsList: function () {
      projectService
        .getAllProjects()
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            this.projectList = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    checkRouteTab(index) {
      this.totalPages = 0;
      if (index == 1) {
        this.submitForm(1);
        this.contractFormList = [];
        this.contractFormListFla = [];
      } else {
        this.getDraftList();
      }
    },

    getDraftList() {
      let filters = {
        type: this.selectedForm.id,
        contract_type: this.selectedForm.contract_type,
        page_no: this.currentPage,

        // contractType: "Agreement"
      };
      console.log(this.form.project_id, "Newww");

      this.draftList = [];

      ContractService.getDraft(filters)
        .then((response) => {
          const { data } = response;

          if (!data.err) {
            if (data.data.length > 0) {
              data.data.map((x) => {
                let obj = JSON.parse(x.draft);
                console.log(
                  obj,
                  obj["projectName"]["project_id"],
                  this.form.project_id
                );
                if (
                  this.form.project_id != "" &&
                  this.form.project_id != null
                ) {
                  if (
                    this.form.project_id.project_id ==
                    obj["projectName"]["project_id"]
                  ) {
                    obj["draft_id"] = x["draft_id"];
                    obj["mou_created_by_name"] = x["draft_created_by"];
                    obj["contract_type"] = x["contract_type"];
                    obj["mou_created_date"] = x["draft_created_date"];
                    this.draftList.push(obj);
                  }
                } else {
                  obj["draft_id"] = x["draft_id"];
                  obj["mou_created_by_name"] = x["draft_created_by"];
                  obj["contract_type"] = x["contract_type"];
                  obj["mou_created_date"] = x["draft_created_date"];
                  this.draftList.push(obj);
                }
              });
              //   this.formType = data.data;
              this.totalPages = data.pagination.last_page;
            } else {
            }
            this.$vs.loading.close();
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getAgreement: function () {
      let filters = {
        contractType: "Agreement",
      };

      ContractService.getContractVendorListMou(filters)
        .then((response) => {
          const { data } = response;
          //  console.log(data);
          if (!data.err) {
            this.formType = data.data;

            let array = data.data.filter((x) => {
              return x.contract_type == 1;
            });
            if (array.length > 0) {
              this.form.typeOfAgreement = array[0];
            } else {
              this.form.typeOfAgreement = data.data[0];
            }

            this.submitForm(1);
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submitForm: function (id, page_no = "") {
      this.$vs.loading();
      this.PDFFLAARRAY = [];
      this.PDFMOUARRAY = [];
      this.selectedForm = this.form.typeOfAgreement;

      // console.log(this.tabIndexCheck, "fsdfds");
      if (this.tabIndexCheck == 0) {
        if (id == 1) {
          this.searchQuery = "";
          this.contractFormList = [];
          this.contractFormListFla = [];
        }

        // console.log(this.form.typeOfAgreement.contract_type,"fsdfdsf");
        if (this.form.typeOfAgreement.contract_type == 1) {
          this.getAllContractMouFormList(page_no, id);
          this.flaFormTable = false;
          this.mouFormTable = true;
        } else if (this.form.typeOfAgreement.contract_type == 2) {
          this.getAllContractFlaFormList(page_no, id);
          this.mouFormTable = false;
          this.flaFormTable = true;
        }
      } else {
        this.getDraftList();
      }
    },
    getAllContractMouFormList: function (page_no, showError) {
      let typeId = this.form.typeOfAgreement.id;

      this.ListVieWName = this.form.typeOfAgreement.name;
      // console.log("typeId :>> ", typeId);
      let project_id = "";
      console.log(this.form.project_id, "fdsf");
      if (this.form.project_id != "" && this.form.project_id != null) {
        project_id = this.form.project_id.project_id;
      }
      ContractService.getAllMouFormList(
        typeId,
        this.searchQuery,
        project_id,
        page_no
      )
        .then((response) => {
          const { data } = response;
          this.$vs.loading.close();

          if (data.data.length > 0 && data.Status) {
            this.contractFormList = data.data;
            this.totalPages = data.pagination.last_page;
            // console.log("this.contractFormList :>> ", this.contractFormList);
          } else {
            // this.$vs.notify({
            //   title: "Error",
            //   text: data.Message,
            //   iconPack: "feather",
            //   icon: "check_box",
            //   color: "warning",
            // });
            this.contractFormList = [];
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.contractFormList = [];
          this.$vs.notify({
            title: "Error",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    getAllContractFlaFormList: function (page_no, showError) {
      let typeId = this.form.typeOfAgreement.id;
      this.ListVieWName = this.form.typeOfAgreement.name;
      let project_id = "";
      console.log(this.form.project_id, "fdsf");
      if (this.form.project_id != "" && this.form.project_id != null) {
        project_id = this.form.project_id.project_id;
      }
      //   console.log("typeId FLA:>> ", typeId);
      ContractService.getAllFlaFormList(
        typeId,
        this.searchQuery,
        project_id,
        page_no
      )
        .then((response) => {
          const { data } = response;
          this.$vs.loading.close();

          if (data.data.length > 0 && data.Status) {
            this.contractFormListFla = data.data;
            this.totalPages = data.pagination.last_page;
          } else {
            if (showError == 1) {
              this.$vs.notify({
                title: "Error",
                text: data.Message,
                iconPack: "feather",
                icon: "check_box",
                color: "warning",
              });
            }
            this.contractFormListFla = [];
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.contractFormListFla = [];

          this.$vs.notify({
            title: "Error",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    onRowDataChanged(e) {
      console.log("e :", e);
    },
    onChangeStatus: function () {
      console.log("object");
    },
  },
  mounted() {
    this.getProjectsList();
    eventBus.$on("PDFMOUARRAY", (e) => {
      console.log("eeeeee MOU call");
      let i = this.PDFMOUARRAY.indexOf(e);
      if (i >= 0) {
        this.PDFMOUARRAY.splice(i, 1);
      } else if (this.PDFMOUARRAY.length < 10) {
        this.PDFMOUARRAY.push(e);
      } else {
        eventBus.$emit("PDFMOUARRAYRemove" + e, true);
        this.$vs.notify({
          title: "Error",
          text: "Select only 10 rows",
          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });
      }

      console.log("eeeeee MOU call", this.PDFMOUARRAY);
    });

    eventBus.$on("PDFFLAARRAY", (e) => {
      console.log("eeeeee PDFFLAARRAYcall");
      let i = this.PDFFLAARRAY.indexOf(e);
      if (i >= 0) {
        this.PDFFLAARRAY.splice(i, 1);
      } else if (this.PDFFLAARRAY.length < 10) {
        this.PDFFLAARRAY.push(e);
      } else {
        eventBus.$emit("PDFFLAARRAYRemove" + e, true);
        this.$vs.notify({
          title: "Error",
          text: "Select only 10 rows",
          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });
      }

      console.log(this.PDFFLAARRAY);
    });
  },
};
</script>
<style>
.dot-edit-icon {
  height: 24px;
  width: 24px;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  transform: translateY(10px);
  margin: 0 auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.dot-edit-icon.low {
  background-color: #ffc107;
}

.dot-edit-icon.high {
  background-color: #dc3545;
}

.dot-edit-icon.normal {
  background-color: #28a745;
}
</style>

