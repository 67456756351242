<!-- =========================================================================================
  File Name: projects.vue
  Description: All Projects list
  ----------------------------------------------------------------------------------------
  Item Name: KOZO Admin - VueJS Dashboard
  Author:
  Author URL:
========================================================================================== -->

<template>
  <div id="dashboard-analytics">
    <div class="vx-row mt-5">
      <div
        class="vx-col w-full lg:w-1/3 mb-5"
        v-for="(item, index) in formContract"
        :key="index"
      >
        <router-link to @click.native="redirectNew(item)">
          <project-card
            :icon="item.icon"
            type="area"
            :titleName="item.titleName"
          ></project-card>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectCard from "@/components/project-cards/projectCard.vue";
import { BASEURL } from "@/config/index.js";
export default {
  data() {
    return {
      excelurl: BASEURL(),
      archive: "",
      gridView: true,
      listView: false,
      searchField: "",
      searchQuery: "",
      isActive: false,
      role_name: "",
      analyticsData: [],
      formContract: [
        // {
        //   titleName: "VENDOR NAME",
        //   icon: "BriefcaseIcon",
        //   path: "/contract/vendorView",
        //   roleName: [],
        //   routeName: "Vendor",
        // },
        {
          titleName: "PRODUCTION HOUSE",
          icon: "UsersIcon",
          path: "/contract/companyView",
          roleName: [],
          routeName: "Company",
        },
        // {
        //   titleName: "USER",
        //   icon: "UsersIcon",
        //   path: "/contract/userAndLevelView",
        //   roleName: [],
        //   routeName: "User"
        // },
        // {
        //   titleName: "CATEGORY",
        //   icon: "GitMergeIcon",
        //   path: "/contract/category",
        //   roleName: [],
        //   routeName: "Category"
        // },
        // {
        //   titleName: "PROJECTS",
        //   icon: "UsersIcon",
        //   path: "/contract/project",
        //   roleName: [],
        //   routeName: "ProjectType"
        // },
        {
          titleName: "TYPE OF AGREEMENT",
          icon: "FileTextIcon",
          path: "/contract/agreement",
          roleName: [],
          routeName: "Agreement",
        },
        {
          titleName: "PLATFORM",
          icon: "LayersIcon",
          path: "/contract/platform",
          roleName: [],
          routeName: "Platform",
        },
        // {
        //   titleName: "TYPE OF PROJECT",
        //   icon: "UsersIcon",
        //   path: "/contract/projectType",
        //   roleName: [],
        //   routeName: "ProjectType"
        // },
        {
          titleName: "REMINDER TYPE",
          icon: "CalendarIcon",
          path: "/contract/reminderType",
          roleName: [],
          routeName: "ReminderType",
        },
        {
          titleName: "LANGUAGE",
          icon: "GlobeIcon",
          path: "/contract/language",
          roleName: [],
          routeName: "Language",
        },
        {
          titleName: "STATUS",
          icon: "PlusCircleIcon",
          path: "/contract/statusView",
          roleName: [],
          routeName: "Status",
        },
        // {
        //   titleName: "TYPE OF SHOW",
        //   icon: "PlusCircleIcon",
        //   path: "/contract/showType",
        //   roleName: [],
        //   routeName: "ShowType"
        // }
      ],
      isImp: false,
      show: false,
    };
  },
  beforeMount: function () {
    window.localStorage.setItem("ContractType", "");
  },
  methods: {
    redirectNew(item) {
      window.localStorage.setItem("ContractType", item.routeName);
      this.$router.push({
        path: item.path,
      });
    },
  },
  components: {
    ProjectCard,
  },
};
</script>

<style lang="scss"></style>
