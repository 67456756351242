<template>
  <div>
    <span
      class="text-center table-edit-icon"
      @click="getPreview()"
      v-if="params.data.uploadDocument != '-'"
    >
      <i class="text-center material-icons">get_app</i>
    </span>
    <h4 class="text-center">{{ params.data.uploadDocument == "-" ? "-" : "" }}</h4>

    <vs-popup
      :id="'grid' + params.value"
      :button-close-hidden="false"
      class="holamundo p-2"
      title="Uploaded Files"
      :active.sync="popupActive"
    >
      <div class>
        <ul class>
          <li
            class="font-weight-bold mb-2 pb-2"
            :class="isDocuments.length > 1 ? 'border-bottom' : ''"
            v-for="(document, docIndex) in isDocuments"
            :key="docIndex"
          >
            <div class="d-flex align-items-center justify-content-between">
              <div>Document {{docIndex+1}}</div>
              <div>
                <span class="float-right">
                  <vs-button
                    color="success"
                    type="filled"
                    @click="onChangeCheckbox(document)"
                    class="mr-3"
                  >view</vs-button>
                  <!-- <vs-button color="danger" type="filled" @click="onChangeDelete(docIndex)">Delete</vs-button> -->
                </span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import Vue from "vue";
import UserService from "@/services/UserService.js";
import { error } from "util";
import moment from "moment";
export default Vue.extend({
  data() {
    return {
      userDetail: [],
      params: null,
      singleUserId: "",
      userEmpDetails: [],
      popupActive: false,
      isDocuments: [],
      tempToken: window.localStorage.getItem("TempToken"),
    };
  },
  components: {
    moment,
  },

  beforeMount: function () {
    // this.value = this.params.data;
    // this.singleUserId = this.value.user_id;
    this.uploadDocument = this.params.value;
    this.isDocuments = this.params.data.uploadDocument.split(",");
  },
  beforeDestroy() {
    // let _id = document.getElementById(
    //   "popmodalView" + this.params.data.uploadDocument
    // );
    // _id.setAttribute("style", "display: none;");
  },
  filters: {},
  computed: {
    // isDocuments: function() {
    //   return ;
    // }
  },
  methods: {
    getPreview: function () {
      let documentsArray = this.params.data.uploadDocument.split(",");
      if (this.params.data.uploadDocument != "-" && documentsArray.length > 1) {
        this.popupActive = true;
      } else if (this.params.data.uploadDocument != "-") {
        this.popupActive = true;
        // window.open(this.params.data.uploadDocument);
      }
    },
    onChangeCheckbox: function (doc) {
      let document = doc + "?token=" + this.tempToken;
      window.open(document);
    },
    onChangeDelete: function (index) {
      this.isDocuments.splice(index, 1);
    },
  },
});
</script>

<style></style>
