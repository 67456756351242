<template>
  <div>
    <!-- <span class="text-center table-edit-icon" @click="showModal">
      <i class="text-center material-icons">remove_red_eye</i>
    </span>-->
    <!-- <b-form-checkbox value="1" unchecked-value="2" v-model="checkBox1" class="table-status-icon"></b-form-checkbox> -->
    <vs-checkbox
      class="table-status-icon"
      v-model="checkBox1"
      @click="PDFExport"
      style="padding-top:12px"
      :disabled="false"
    ></vs-checkbox>
    <!-- POPUP -->
  </div>
</template>

<script>
import Vue from "vue";
import departmentService from "@/services/departmentService.js";
import purchaseOrderService from "@/services/purchaseOrderService.js";
import ContractService from "@/services/ContractService.js";
import CategoryService from "@/services/categoryService.js";
import CountryService from "@/services/countryService.js";
//
import _ from "lodash";

export default Vue.extend({
  data() {
    return {
      checkBox1: false,
      mod_user: [],
      LoginUserId: "",
      userLevelLogin: "",
      admin_user: [],
      purchaseData: {},
      checkBoxStatus: false,
      rejectionComment: "",
      acceptComment: "",
      popupActive: false,
      price: null,
      is_paid: null,
      popupActive1: false,
      popupActive2: false,
      popupActive3: false,
      submitStatus: false,
      params: null,
      value: null,
      count: 0,
      user_id: "",
      purchase_status: "",
      map_id: "",
      pmap_id: "",
      project_id: "",
      fla_id: "",
      form: {
        parent_id: "",
        category_id: ""
      },
      isPopup: null,
      POuser: "",
      acceptReason: "",
      levels: [],
      countries: [],
      cities: [],
      country_value: [],
      city_value: [],
      project_value: [],
      allProjects: [],
      // allCategories: [],
      // sub_categories: [],
      categories: [],
      subcategories: [],
      categoryValue: null,
      subcategoryValue: null
      // Testpopup: false
    };
  },
  watch: {},
  beforeMount() {
    // this.purchase_status = this.params.data.purchase_status;
    this.form = this.params.data;
    //  this.proj_id = this.params.data.project_id;
    this.isPopup = this.params.data.isPopup;
    //  this.is_paid = this.params.data.is_already_paid;
    // this.UserRole = window.localStorage.getItem("UserRole");
  },
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodale" + this.params.data.fla_id ||
        "popmodalr" + this.params.data.fla_id
    );
    _id.setAttribute("style", "display: none;");
  },
  computed: {
    UserRole() {
      return localStorage.getItem("UserRole") || null;
    }
  },
  methods: {
    PDFExport() {
      console.log("fdsfdsf", this.checkBox1);
      setTimeout(() => {
        eventBus.$emit("PDFFLAARRAY", this.params.data.fla_id);
      }, 1);
    }
  },
  mounted() {
    this.fla_id = this.params.data.fla_id;
    this.purchaseData = this.params.data;
    eventBus.$on("PDFFLAARRAYRemove" + this.params.data.fla_id, e => {
      // console.log("fsdfdsfdsf  FLA dsf");
      this.checkBox1 = this.checkBox1 ? false : true;
    });
  }
});
</script>
<style>
.po-popup .vs-popup--content {
  height: 100%;
}
</style>
