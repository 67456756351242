<template>
  <div>
    <span class="text-center table-edit-icon" @click="showModal">
      <i class="text-center material-icons">remove_red_eye</i>
    </span>

    <!-- POPUP -->
    <div>
      <vs-popup
        :id="'popmodalr' + params.data.mou_id"
        title="Reject PO"
        :active.sync="popupActive1"
      >
        <div class="text-center">
          <h5>Reason For rejection</h5>
          <!-- SUBMIT BUTTON -->
          <div class="vx-row mt-5">
            <div class="vx-col w-full">
              <vs-textarea v-model="rejectionComment" class="mb-4" rows="4" />
              <!-- :disabled="submitStatus" -->
              <vs-button color="success" type="filled" @click="onSubmit()" class="mr-3">Submit</vs-button>

              <vs-button color="danger" type="filled" @click="privStatus()">Cancel</vs-button>
            </div>
          </div>
        </div>
      </vs-popup>

      <vs-popup
        :id="'popmodalr' + params.data.mou_id"
        title="Accept PO"
        :active.sync="popupActive2"
      >
        <div class="text-center">
          <h5>Reason For Accept (Exceeded Budget) PO</h5>
          <!-- SUBMIT BUTTON -->
          <div class="vx-row mt-5">
            <div class="vx-col w-full">
              <vs-textarea v-model="acceptComment" class="mb-4" rows="4" />

              <vs-button color="success" type="filled" @click="onSubmitPO()" class="mr-3">Submit</vs-button>

              <vs-button color="danger" type="filled" @click="privStatus()">Cancel</vs-button>
            </div>
          </div>
        </div>
      </vs-popup>

      <vs-popup
        :id="'popmodalr' + params.data.mou_id"
        title="Accept PO"
        :active.sync="popupActive3"
      >
        <div class="text-center">
          <h5 v-if="String(params.data.approver_id) == '0'">Reason For Accept Expense</h5>
          <h5 v-else>Reason For Accept PO</h5>
          <!-- SUBMIT BUTTON -->
          <div class="vx-row mt-5">
            <div class="vx-col w-full">
              <vs-textarea v-model="acceptComment" class="mb-4" rows="4" />

              <vs-button color="success" type="filled" @click="onSubmitPO()" class="mr-3">Submit</vs-button>

              <vs-button color="danger" type="filled" @click="privStatus()">Cancel</vs-button>
            </div>
          </div>
        </div>
      </vs-popup>

      <vs-popup
        :id="'popmodale' + params.data.mou_id"
        :title="
           `Approval Status`
        "
        class="po-popup"
        :active.sync="popupActive"
      >
        <div class="row mx-0 pt-5 h-100">
          <div class="col-md-12">
            <div class="mb-5 p-3">
              <!-- USER -->
              <ul class="level-list mb-5" v-if="levels.length>0">
                <li v-for="(level, levelIndex) in levels" :key="levelIndex">
                  <h5>
                    <!-- <img src="@/assets/images/icons/level2.svg" height="20" width="20" alt /> -->
                    Level {{ level.user_level }}
                  </h5>
                  <div class="col-11 ml-auto px-0">
                    <!-- LOOP THIS -->
                    <div
                      v-for="(singleUser, userIndex) in level.user_list"
                      :key="'user' + userIndex"
                      class="d-flex align-items-center py-2 border-bottom"
                    >
                      <!-- APPROVED USER-->
                      <div v-if="singleUser.approved_status == 2" class="d-flex align-items-center">
                        <div class="mr-3">
                          <img src="@/assets/images/icons/approved-tick.svg" height="30" width="30" />
                        </div>

                        <div>
                          <span class="font-weight-bold">
                            {{
                            singleUser.user_name
                            }}
                          </span>
                          &nbsp; has approved your contract .
                        </div>
                      </div>

                      <!-- PENDING USER -->
                      <div v-if="singleUser.approved_status == 1" class="d-flex align-items-center">
                        <div class="mr-3">
                          <img
                            src="@/assets/images/icons/approval-pending.svg"
                            height="30"
                            width="30"
                          />
                        </div>Approval pending from&nbsp;
                        <span class="font-weight-bold">
                          {{
                          singleUser.user_name
                          }}
                        </span>
                        <!-- <div
                          v-if="
                            UserRole != 'Admin' &&
                              (LoginUserId == singleUser.user_id ||
                                UserRole.includes('Manager') ||
                                (singleUser.user_level > userLevelLogin &&
                                  UserRole.includes('User')))
                          "
                          v-on:click="approvePO(singleUser, 'user')"
                          class="ml-2"
                        >
                          <feather-icon
                            icon="CheckCircleIcon"
                            class="cursor-pointer text-success"
                            svgClasses="h-8 w-8"
                          />
                        </div>-->
                        <!-- <div
                          v-if="
                            UserRole != 'Admin' &&
                              (LoginUserId == singleUser.user_id ||
                                UserRole.includes('Manager'))
                          "
                          v-on:click="rejectPO(singleUser)"
                          class="ml-2"
                        >
                          <feather-icon
                            icon="XCircleIcon"
                            class="cursor-pointer text-danger"
                            svgClasses="h-8 w-8"
                          />
                        </div>-->
                      </div>
                      <!-- NOT YET REACHED TO USER -->
                      <div v-if="singleUser.approved_status == 0" class="d-flex">
                        <div class="mr-3">
                          <img
                            src="@/assets/images/icons/approval-pending.svg"
                            height="30"
                            width="30"
                          />
                        </div>Not Yet Reached&nbsp;
                        <span class="font-weight-bold">
                          {{
                          singleUser.user_name
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>

              <!-- ADMIN -->
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import departmentService from "@/services/departmentService.js";
import purchaseOrderService from "@/services/purchaseOrderService.js";
import ContractService from "@/services/ContractService.js";
import CategoryService from "@/services/categoryService.js";
import CountryService from "@/services/countryService.js";
//
import _ from "lodash";

export default Vue.extend({
  data() {
    return {
      mod_user: [],
      LoginUserId: "",
      userLevelLogin: "",
      admin_user: [],
      purchaseData: {},
      rejectionComment: "",
      acceptComment: "",
      popupActive: false,
      price: null,
      is_paid: null,
      popupActive1: false,
      popupActive2: false,
      popupActive3: false,
      submitStatus: false,
      params: null,
      value: null,
      count: 0,
      user_id: "",
      purchase_status: "",
      map_id: "",
      pmap_id: "",
      project_id: "",
      mou_id: "",
      form: {
        parent_id: "",
        category_id: ""
      },
      isPopup: null,
      POuser: "",
      acceptReason: "",
      levels: [],
      countries: [],
      cities: [],
      country_value: [],
      city_value: [],
      project_value: [],
      allProjects: [],
      // allCategories: [],
      // sub_categories: [],
      categories: [],
      subcategories: [],
      categoryValue: null,
      subcategoryValue: null
      // Testpopup: false
    };
  },
  watch: {},
  beforeMount() {
    // this.purchase_status = this.params.data.purchase_status;
    this.form = this.params.data;
    //  this.proj_id = this.params.data.project_id;
    this.isPopup = this.params.data.isPopup;
    //  this.is_paid = this.params.data.is_already_paid;
    // this.UserRole = window.localStorage.getItem("UserRole");
  },
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodale" + this.params.data.mou_id ||
        "popmodalr" + this.params.data.mou_id
    );
    _id.setAttribute("style", "display: none;");
  },
  computed: {
    UserRole() {
      return localStorage.getItem("UserRole") || null;
    }
  },
  methods: {
    showModal() {
      this.popupActive = true;
      // this.getProjectList();
      // this.getCategoryList();
      // this.getCountryList();
      // this.getCityList();
      // this.getSubCategoryList(this.purchaseData.category_id);
      this.getAllFormDetails(this.params.data.mou_id);
    },
    getAllFormDetails: function(ID) {
      this.$vs.loading();
      // call api
      ContractService.getSignleMouFormDetails(ID)
        .then(response => {
          const { data } = response;
          console.log(data.data);
          if (data.Status) {
            this.levels = data.data[0].hierachyArray;
            this.$vs.loading.close();
            //console.log(this.levels, "fdsfds");
          }
        })
        .catch(err => {});
    },

    // getSubCategoryList: function(categoryID) {
    //   console.log("categoryID :", categoryID);
    //   let payload = {
    //     categoryID: categoryID
    //   };
    //   CategoryService.getSubCategoryByCategory(payload).then(response => {
    //     const data = response.data;
    //     if (!data.error && data) {
    //       this.sub_categories = data.data;
    //       this.sub_categories.map(x => {
    //         if (this.params.data.sub_category_id == x.sub_category_id) {
    //           this.subcategoy_value = x;
    //         }
    //       });
    //       // console.log("data :", data);
    //       // this.categories = data.data;
    //     } else {
    //       // Show Error Message, Whcih is comming from backend5
    //     }
    //   });
    // },
    submitForm: function() {
      this.$validator.validateAll().then(result => {
        if (result) {
          let payload = {
            project_id: this.purchaseData.project_id,
            user_id: this.purchaseData.user_id,
            po_name: this.purchaseData.po_name,
            item_name: this.purchaseData.item_name,
            company_name: this.purchaseData.company_name,
            company_address: this.purchaseData.company_address,
            price: this.purchaseData.total,
            currency: this.purchaseData.currency,
            VHC_code: this.purchaseData.VHC_code,
            sgst: "1",
            cgst: "1",
            total: this.purchaseData.total,
            purchase_image: this.purchaseData.purchase_image,
            parent_id: this.form.parent_id,
            category_id: this.form.category_id,
            city_id: this.city_value.city_id,
            country_id: this.country_value.country_id,
            purchase_description: this.purchaseData.purchase_description,
            purchase_status: this.purchaseData.purchase_status,
            approved_percentage: this.purchaseData.approved_percentage,
            purchase_active: this.purchaseData.purchase_active
          };

          purchaseOrderService
            .editPurchaseOrder(payload, this.mou_id)
            .then(response => {
              const { data } = response;
              if (data.Status == true) {
                this.$vs.notify({
                  title: "Updated!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "success"
                });
                eventBus.$emit("refreshPOList", data);
              } else {
                this.$vs.notify({
                  title: "Error!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning"
                });
              }
            })
            .catch(err => {});
        }
      });
    },
    approvePO: function(user, type) {
      // this.Testpopup = true;
      if (type == ("user", "admin") && this.isPopup == 1) {
        this.POuser = user;
        this.popupActive2 = true;
      } else {
        this.POuser = user;
        this.popupActive3 = true;
        // this.approvePurchaseOrder(user);
      }
    },

    onSubmitPO: function() {
      if (this.isPopup == 1) {
        if (this.acceptComment.length > 0) {
          this.acceptReason = this.acceptComment;
          this.approvePurchaseOrder(this.POuser, this.acceptReason);
        } else {
          this.$vs.notify({
            title: "Please provide a reason!",
            iconPack: "feather",
            icon: "check_box",
            color: "warning"
          });
        }
      } else {
        this.acceptReason = this.acceptComment;
        this.approvePurchaseOrder(this.POuser, this.acceptReason);
      }
    },

    approvePurchaseOrder: function(user, reason) {
      let obj = {
        project_id: user.project_id,
        mou_id: this.mou_id,
        user_id: user.user_id,
        reason: reason
      };
      purchaseOrderService.approvePurchaseOrderDashboard(obj).then(response => {
        const { data } = response;
        if (data.Status) {
          this.getPurchaseMapDetails();
          this.$vs.notify({
            title: "Success",
            text: data.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "success"
          });
          this.popupActive2 = false;
          this.popupActive3 = false;
          setTimeout(() => {
            eventBus.$emit("refreshPOList", data);
          }, 1);
          // this.purchaseData = data.data;
        } else {
          this.$vs.notify({
            title: "Opps!",
            text: data.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning"
          });
        }
      });
    },

    privStatus: function() {
      this.popupActive1 = false;
      this.popupActive2 = false;
      this.popupActive3 = false;
    },
    onSubmit: function() {
      if (this.rejectionComment.length > 0) {
        let obj = {
          project_id: this.project_id,
          mou_id: this.mou_id,
          user_id: this.user_id
        };
        purchaseOrderService
          .rejectPurchaseOrderDashboard(obj, {
            reason: this.rejectionComment
          })
          .then(response => {
            const { data } = response;
            if (data.Status == true) {
              this.popupActive1 = false;
              this.rejectionComment = "";
              this.user_id = "";
              this.map_id = "";
              this.getPurchaseMapDetails();
              this.$vs.notify({
                title: "Expense has been rejected!",
                text: data.Message,
                iconPack: "feather",
                icon: "check_box",
                color: "success"
              });
              setTimeout(() => {
                eventBus.$emit("refreshPOList", data);
              }, 1);
              // this.$router.push({
              // path: "/user-po/?tabIndex=0"
              // });
              // this.purchaseData = data.data;
            } else {
              this.$vs.notify({
                title: "Opps!",
                text: data.Message,
                iconPack: "feather",
                icon: "check_box",
                color: "warning"
              });
            }
          });
      } else {
        this.$vs.notify({
          title: "Please provide a reason!",
          // text: data.error.message,
          iconPack: "feather",
          icon: "check_box",
          color: "warning"
        });
      }
    },
    rejectPO: function(user) {
      this.user_id = user.user_id;
      this.mou_id = user.mou_id;
      this.project_id = user.project_id;
      this.popupActive1 = true;
    }
  },
  mounted() {
    this.mou_id = this.params.data.mou_id;
    this.purchaseData = this.params.data;
  }
});
</script>
<style>
.po-popup .vs-popup--content {
  height: 100%;
}
</style>
